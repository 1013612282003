import {
  PublicPlan as PublicPlanV2,
  Plan as PlanV2,
  PeriodUnit,
  Duration as DurationV2,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  AppliedAt,
  BillingTerms,
  EndType,
  Period,
  Plan as PlanV3,
  PriceType,
  PricingVariant,
  StartType,
  Status,
  Visibility,
  Duration as DurationV3,
} from '@wix/ambassador-pricing-plans-v3-plan/types';
import { getPlanType, isRecurringPlan, isUnlimitedPlan } from '@wix/pricing-plans-utils';
import {
  CoverImage as CoverImageV2,
  getCoverImage,
  getTermsAndConditionsSettings,
  getThankYouPageSettings,
  TermsAndConditionsSettings as TermsAndConditionsSettingsV2,
  ThankYouPageSettings as ThankYouPageSettingsV2,
} from '@wix/pricing-plans-utils/client-data';

enum ExtendedFieldNamespace {
  Groups = '@groups/group-settings',
  OnlinePrograms = '@achievements/online-programs',
}

const extendedFieldKeys = {
  [ExtendedFieldNamespace.Groups]: 'groupsAutoJoin',
  [ExtendedFieldNamespace.OnlinePrograms]: 'groupsAutoJoin',
};

export function planV2ToDemoPlanV3(planV2: PublicPlanV2 | PlanV2): PlanV3 {
  const planType = getPlanType(planV2);
  const termsAndConditionsSettingsV2 = getTermsAndConditionsSettings(planV2.clientData);
  const thankYouPageSettingsV2 = getThankYouPageSettings(planV2.clientData);
  const coverImage = getCoverImage(planV2.clientData);
  const isPlanPublic = 'public' in planV2 ? planV2.public : true;
  const isPlanArchived = 'archived' in planV2 ? planV2.archived : false;

  return {
    id: planV2.id,
    revision: '1',
    createdDate: planV2.createdDate,
    updatedDate: planV2.updatedDate,
    name: planV2.name!,
    description: planV2.description,
    image: coverImageV2ToV3(coverImage),
    slug: planV2.slug,
    termsAndConditions: planV2.termsAndConditions,
    maxPurchasesPerBuyer: planV2.maxPurchasesPerBuyer ?? undefined,
    pricingVariants: [createPricingVariant(planV2)],
    perks: perksV2ToV3(planV2.perks),
    visibility: isPlanPublic ? Visibility.PUBLIC : Visibility.PRIVATE,
    buyable: true,
    status: Status.ACTIVE,
    formId: planV2.formId,
    buyerCanCancel: planV2.buyerCanCancel ?? undefined,
    archived: isPlanArchived,
    primary: planV2.primary,
    currency: planV2.pricing?.price?.currency,
    formType: planType,
    termsAndConditionsSettings: termsAndConditionsSettingsV2ToV3(termsAndConditionsSettingsV2),
    thankYouPageSettings: thankYouPageSettingsV2ToV3(thankYouPageSettingsV2),
    displayIndex: null,
    extendedFields: clientDataToExtendedFields(planV2.clientData),
  };
}

function coverImageV2ToV3(coverImage: CoverImageV2 | undefined): PlanV3['image'] {
  return coverImage
    ? {
        id: coverImage.uri,
        height: coverImage.height,
        width: coverImage.width,
        altText: coverImage.altText,
      }
    : undefined;
}

function perksV2ToV3(perks: PlanV2['perks']): PlanV3['perks'] {
  return perks?.values?.map((perk, i) => ({
    id: getFakeUuid(i.toString()),
    description: perk,
  }));
}

function termsAndConditionsSettingsV2ToV3(
  termsSettings: TermsAndConditionsSettingsV2,
): PlanV3['termsAndConditionsSettings'] {
  if (!termsSettings) {
    return undefined;
  }

  return { acceptedByDefault: termsSettings.isAcceptedByDefault, acceptRequired: termsSettings.acceptRequired };
}

function thankYouPageSettingsV2ToV3(thankYouPageSettingsV2: ThankYouPageSettingsV2): PlanV3['thankYouPageSettings'] {
  if (!thankYouPageSettingsV2) {
    return undefined;
  }

  return {
    title: thankYouPageSettingsV2.title,
    message: thankYouPageSettingsV2.message,
    buttonText: thankYouPageSettingsV2.buttonText,
    buttonLink: thankYouPageSettingsV2.buttonLink,
  };
}

function clientDataToExtendedFields(clientData: PlanV2['clientData']): PlanV3['extendedFields'] {
  const groupsAutoJoin = clientData?.[extendedFieldKeys[ExtendedFieldNamespace.Groups]];
  const onlineProgramsAutoJoin = clientData?.[extendedFieldKeys[ExtendedFieldNamespace.OnlinePrograms]];

  if (groupsAutoJoin === undefined && onlineProgramsAutoJoin === undefined) {
    return undefined;
  }

  return {
    namespaces: {
      [ExtendedFieldNamespace.Groups]: groupsAutoJoin ? { groupsAutoJoin } : {},
      [ExtendedFieldNamespace.OnlinePrograms]: onlineProgramsAutoJoin ? { onlineProgramsAutoJoin } : {},
    },
  };
}

function createPricingVariant(plan: PlanV2): PricingVariant {
  return {
    id: getFakeUuid(),
    name: plan.name!,
    freeTrialDays: plan.pricing?.freeTrialDays ?? 0,
    fees: plan.pricing?.feeConfigs?.map((feeConfig, i) => ({
      id: getFakeUuid(i.toString()),
      appliedAt: AppliedAt.FIRST_PAYMENT,
      priceType: PriceType.FIXED_AMOUNT,
      fixedAmountOptions: { amount: feeConfig.fee?.amount },
      name: feeConfig.fee?.name,
    })),
    promotion: null,
    pricingStrategies: [{ flatRate: { amount: plan.pricing?.price?.value } }],
    billingTerms: createBillingTerms(plan),
  };
}

function createBillingTerms(plan: PlanV2): BillingTerms {
  const startType = plan.allowFutureStartDate ? StartType.CUSTOM : StartType.ON_PURCHASE;
  const isRecurring = isRecurringPlan(plan);
  const isUnlimited = isUnlimitedPlan(plan);

  if (isRecurring) {
    const subscription = plan.pricing?.subscription;
    const billingCycle = durationV2ToV3(subscription?.cycleDuration);

    if (isUnlimited) {
      return {
        startType,
        billingCycle,
        endType: EndType.UNTIL_CANCELLED,
      };
    }

    return {
      startType,
      billingCycle,
      endType: EndType.CYCLES_COMPLETED,
      cyclesCompletedDetails: { billingCycleCount: subscription?.cycleCount?.toString() },
    };
  }

  if (isUnlimited) {
    return {
      startType,
      endType: EndType.UNTIL_CANCELLED,
    };
  }

  return {
    startType,
    endType: EndType.CYCLES_COMPLETED,
    billingCycle: durationV2ToV3(plan.pricing?.singlePaymentForDuration),
    cyclesCompletedDetails: {
      billingCycleCount: '1',
    },
  };
}

function durationV2ToV3(duration?: DurationV2): DurationV3 | undefined {
  if (!duration) {
    return undefined;
  }

  return {
    period: periodUnitV2ToPeriodV3(duration.unit),
    count: duration.count?.toString() ?? undefined,
  };
}

function periodUnitV2ToPeriodV3(unit?: PeriodUnit): Period {
  switch (unit) {
    case PeriodUnit.DAY:
      return Period.DAY;
    case PeriodUnit.WEEK:
      return Period.WEEK;
    case PeriodUnit.MONTH:
      return Period.MONTH;
    case PeriodUnit.YEAR:
      return Period.YEAR;
    default:
      return Period.UNKNOWN_PERIOD;
  }
}

function getFakeUuid(endChar?: string): string {
  if (endChar) {
    return `00000000-0000-0000-0000-${endChar.toString().padStart(12, '0')}`;
  }

  return '00000000-0000-0000-0000-000000000000';
}
