import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { FormValues } from '@wix/form-viewer';
import { FormKind, initFormController } from '@wix/form-viewer/controller';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WIX_FORMS_NAMESPACE } from '../../../constants/wix-forms';
import { DEFAULT_MOCK_PLAN_ID, DEMO_FORM_ID } from '../../../fixtures';

export class FormsService {
  constructor(private readonly flowAPI: ControllerFlowAPI) {}

  async maybeInitFormController(plan: Plan) {
    if (plan.formId) {
      const isDemoForm = plan.id === DEFAULT_MOCK_PLAN_ID && plan.formId === DEMO_FORM_ID;
      await initFormController(this.flowAPI, {
        formId: plan.formId,
        namespace: WIX_FORMS_NAMESPACE,
        formKind: isDemoForm ? FormKind.EXTENSION : FormKind.REGULAR,
      });
    }
  }

  async maybeSubmitForm(formId: Plan['formId'], values?: FormValues | null): Promise<string | null> {
    if (!formId || !values) {
      return null;
    }
    try {
      const result = await this.flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: values,
          },
        }),
      );
      return result.data.submission?.id ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
