import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { isCustomPurchaseFlow, PlanCustomizationPublicData } from '@wix/pricing-plans-router-utils';
import type { CreateControllerFn, ControllerParams, ControllerFlowAPI, FlowAPI } from '@wix/yoshi-flow-editor';
import { DEMO_FORM_ID, plansFixtureTranslated } from '../../fixtures';
import { resolveLocale } from '../../utils';
import { getCustomSuccessPageUrl, getPlanOptions } from '../../utils/ecom';
import { planV2ToDemoPlanV3 } from './mappers/plan-v2-to-demo-plan-v3';
import { EcomService } from './services/ecom';
import { FormsService } from './services/forms';
import { PlanCustomization } from './types';
import { RootProductPageProps } from './Widget';

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  return {
    async pageReady() {
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const setProps: (props: Partial<RootProductPageProps>) => void = flowAPI.controllerConfig.setProps;
      const formsService = new FormsService(flowAPI);
      const ecomService = new EcomService(flowAPI.httpClient, wixCodeApi);

      setProps({
        status: 'PAGE_LOADING',
        formControllerStatus: 'LOADING',
        locale: resolveLocale(wixCodeApi),
        proceedToPayment,

        // @ts-expect-error Undocumented viewer prop, which adds "height: auto" to root TPA section
        fitToContentHeight: true,
      });

      const routerData = await getProductPageData(flowAPI);
      const {
        plan,
        customizationData: { integrationData },
      } = routerData;

      formsService
        .maybeInitFormController(plan)
        .then(() => {
          setProps({ formControllerStatus: 'READY' });
        })
        .catch(() => {
          setProps({ formControllerStatus: 'ERROR' });
        });
      setProps({
        status: 'PAGE_READY',
        plan,
        minStartDate: integrationData.minStartDate ? integrationData.minStartDate : undefined,
        maxStartDate: integrationData.maxStartDate ? integrationData.maxStartDate : undefined,
      });

      async function proceedToPayment({ formValues, startDate }: PlanCustomization) {
        try {
          setProps({ status: 'CHECKOUT_LOADING' });
          const submissionId = await formsService.maybeSubmitForm(plan.formId, formValues);

          const options = getPlanOptions({ plan, submissionId, startDate, integrationData });
          const checkout = await ecomService.createCheckout(plan.id!, options);

          const successUrl = isCustomPurchaseFlow(integrationData)
            ? await getCustomSuccessPageUrl({
                wixCodeApi,
                integrationData,
                checkoutId: checkout.id!,
                planId: routerData.plan.id!,
              })
            : undefined;

          await ecomService.navigateToCheckout(checkout.id!, successUrl);
        } catch (e) {
          setProps({ status: 'ERROR' });
        }
      }
    },
    async updateConfig() {},
  };
};

export default createController;

async function getProductPageData(flowAPI: ControllerFlowAPI): Promise<PlanCustomizationPublicData> {
  const routerData = flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData<PlanCustomizationPublicData>();
  if (!routerData) {
    if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
      const demoPlan = getDemoPlanV3(flowAPI.translations.t);
      return {
        plan: demoPlan,
        customizationData: {
          integrationData: {},
          planId: demoPlan.id!,
        },
      };
    }

    // TODO: Add fallback client calls to get the data
    throw new Error('Router data is missing');
  }

  return routerData;
}

function getDemoPlanV3(t: FlowAPI['translations']['t']): Plan {
  const [planV2] = plansFixtureTranslated(t);
  return planV2ToDemoPlanV3({
    ...planV2,
    formId: DEMO_FORM_ID,
    allowFutureStartDate: true,
  });
}
